<template>
  <div class="d-inline-block float-right">
    <el-button-group>
      <el-button
          :size="fieldsSize"
          @click="play"
          v-if="currentStopwatch.entityId !== data.id"
      >
        <font-awesome-icon
            icon="play"
        />
      </el-button>
      <el-button
          :size="fieldsSize"
          @click="add"
          v-if="!allStopwatchesEntitiesIds.includes(data.id)"
      >
        <font-awesome-icon
            icon="plus"
        />
      </el-button>
    </el-button-group>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import abstractForm from '../../mixin/index';
// import CustomButton from '../../UI/Button.vue'

export default {
  name: 'index',
  mixins: [abstractForm],
  // components: {CustomButton},
  computed: {
    ...mapState('stopwatches', [
      'currentStopwatch',
    ]),
    ...mapGetters('stopwatches', [
      'allStopwatchesEntitiesIds',
    ]),
  },
  data() {
    return {
      fieldsSize: 'small',
    };
  },
  methods: {
    ...mapActions('stopwatches', ['startStopwatch', 'addStopwatch']),
    play() {
      this.startStopwatch(this.data.id);
    },
    add() {
      this.addStopwatch(this.data.id);
    },
  },
};
</script>

<style scoped>

</style>
